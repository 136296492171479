import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import ScoreboardRow from './ScoreboardRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CurrentScoresCollection } from '../../static/countries';
import { Link } from 'react-router-dom/dist';

const ScoreboardIndex = () => {
    const [loading, setLoading] = useState(true);
    const [groupedScores, setGroupedScores] = useState();
    const { currentUser } = useAuth();

    useEffect(() => {
        db.collection(CurrentScoresCollection)
            .where('active', '==', true)
            .onSnapshot((querySnapshot) => {
                var scores = [];
                querySnapshot.forEach((doc) => {
                    scores.push(doc.data());
                });
                const grouped = scores.reduce((acc, cur) => {
                    if (!acc) {
                        return [{ code: cur.countryCode, allscores: [cur], score: cur.score }];
                    }
                    let current = acc.find((a) => a.code === cur.countryCode);
                    if (current) {
                        current.allscores.push(cur);
                        current.score = (Number(current.score) + Number(cur.score)).toString();
                    } else {
                        acc.push({ code: cur.countryCode, allscores: [cur], score: cur.score });
                    }
                    return acc;
                }, []);
                setGroupedScores(grouped.sort((a, b) => Number(b.score) - Number(a.score)));
            });
    }, []);
    useEffect(() => {
        setLoading(!groupedScores);
    }, [groupedScores]);

    if (loading) {
        return <></>;
    }
    if (groupedScores && groupedScores.length > 0) {
        return (
            <div className="main-elements">
                <h4>Poengtavla! Klikk på et land for å endre dine poeng og se hva andre har gitt!</h4>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Land</TableCell>
                                <TableCell>Sang</TableCell>
                                <TableCell>Poeng</TableCell>
                                <TableCell>Dine</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupedScores.map((score, index) => (
                                <ScoreboardRow key={index + score.code} row={score} userId={currentUser.uid} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    } else {
        return (
            <div>
                <p>
                    Ingen har registrert noen poeng enda, vent til programmet starter og gå til{' '}
                    <Link to="/stage" style={{ color: 'white', textDecoration: 'underline' }}>
                        Scenen
                    </Link>
                </p>
            </div>
        );
    }
};

export default ScoreboardIndex;
