import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

const ChatMessage = ({ message, currentUser }) => {
    const currentDate = dayjs(message.sentAt.toDate());
    return (
        <div className={`message${currentUser.uid === message.sentBy.userId ? ' own' : ''}`}>
            <Typography className="name">{message.sentBy.name}</Typography>
            <Typography className="text">{message.messageText}</Typography>
            {message.sentAt ? (
                <div className="date" title={currentDate.format('LLL')}>
                    {currentDate.fromNow()}
                </div>
            ) : null}
        </div>
    );
};
export default ChatMessage;
