// Code and flag source: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
const allCountries = {
    ISR: {
        name: 'Israel',
        artist: 'Eden Golan',
        song: 'Hurricane',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e0238f0b282ea6497b296def873',
        orderNr: 6,
        shortCode: 'il',
    },
    GEO: {
        name: 'Georgia',
        artist: 'Nutsa Buzaladze',
        song: 'Firefighter',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02141987739fb2a31565908fed',
        orderNr: 24,
        shortCode: 'ge',
    },
    LVA: {
        name: 'Latvia',
        artist: 'Dons',
        song: 'Hollow',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02e8ef06274d5def9343a16c70',
        orderNr: 11,
        shortCode: 'lv',
    },
    AUT: {
        name: 'Østerrike',
        artist: 'Kaleen',
        song: 'We Will Rave',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e0233564b826ec8b117c7a05906',
        orderNr: 26,
        shortCode: 'at',
    },
    CYP: {
        name: 'Kypros',
        artist: 'Silia Kapsis',
        song: 'Liar',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e027443b10d277137e30d47154b',
        orderNr: 20,
        shortCode: 'cy',
    },
    IRL: {
        name: 'Irland',
        artist: 'Bambie Thug',
        song: 'Doomsday Blue',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02bcc43f5065cb2a61215063be',
        orderNr: 10,
        shortCode: 'ie',
    },
    HRV: {
        name: 'Kroatia',
        artist: 'Baby Lasagna',
        song: 'Rim Tim Tagi Dim',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02499140117cbdffaca51e02b5',
        orderNr: 23,
        shortCode: 'hr',
    },
    SVN: {
        name: 'Slovenia',
        artist: 'Raiven',
        song: 'Veronika',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02109b3013b49547e92abc021f',
        orderNr: 22,
        shortCode: 'si',
    },
    LUX: {
        name: 'Luxemburg',
        artist: 'TALI',
        song: 'Fighter',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02a4746de24dd6e3fbdae4d5ff',
        orderNr: 4,
        shortCode: 'lu',
    },
    PRT: {
        name: 'Portugal',
        artist: 'iolanda',
        song: 'Grito',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e024f110d2b22779e5d24027b94',
        orderNr: 18,
        shortCode: 'pt',
    },
    FIN: {
        name: 'Finland',
        artist: 'Windows95man',
        song: 'No rules!',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02b9ed3a94d94135ec6c3c9c2a',
        orderNr: 17,
        shortCode: 'fi',
    },
    CHE: {
        name: 'Sveits',
        artist: 'Nemo',
        song: 'The Code',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e028b736cc468fee080a0e8dad0',
        orderNr: 21,
        shortCode: 'ch',
    },
    FRA: {
        name: 'Frankrike',
        artist: 'Slimane',
        song: 'Mon amour',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e022f96c983f32d70f614091d0d',
        orderNr: 25,
        shortCode: 'fr',
    },
    NOR: {
        name: 'Norge',
        artist: 'Gåte',
        song: 'Ulveham',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02b5aaa95012874ec6141469b6',
        orderNr: 14,
        shortCode: 'no',
    },
    ARM: {
        name: 'Armenia',
        artist: 'LADANIVA',
        song: 'Jako',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e027ffcf7a22cb5aece4d824ec0',
        orderNr: 19,
        shortCode: 'am',
    },
    ITA: {
        name: 'Italia',
        artist: 'Angelina Mango',
        song: 'La noia',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e022f4c9c6288bf8e2075833abc',
        orderNr: 15,
        shortCode: 'it',
    },
    ESP: {
        name: 'Spania',
        artist: 'Nebulossa',
        song: 'ZORRA',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02ea9b1f22906119b42edf93f7',
        orderNr: 8,
        shortCode: 'es',
    },
    NLD: {
        name: 'Nederland',
        artist: 'Joost Klein',
        song: 'Europapa',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e0264593914322faaf17150d522',
        orderNr: 5,
        shortCode: 'nl',
    },
    UKR: {
        name: 'Ukraina',
        artist: 'alyona alyona & Jerry Heil',
        song: 'Teresa & Maria',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e0271f99b38ac1c81d04abc4203',
        orderNr: 2,
        shortCode: 'ua',
    },
    DEU: {
        name: 'Tyskland',
        artist: 'ISAAK',
        song: 'Always On The Run',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02a598c7307ed639bd1aba9fd9',
        orderNr: 3,
        shortCode: 'de',
    },
    LTU: {
        name: 'Litauen',
        artist: 'Silvester Belt',
        song: 'Luktelk',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02427d065b09202fb8436b02af',
        orderNr: 7,
        shortCode: 'lt',
    },
    GRC: {
        name: 'Hellas',
        artist: 'Marina Satti',
        song: 'ZARI',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e0236a074cead5538e71f070286',
        orderNr: 12,
        shortCode: 'gr',
    },
    SWE: {
        name: 'Sverige',
        artist: 'Marcus & Martinus',
        song: 'Unforgettable',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02dbabdda2756a52cdbc6077a5',
        orderNr: 1,
        shortCode: 'se',
    },
    GBR: {
        name: 'Storbritannia',
        artist: 'Olly Alexander',
        song: 'Dizzy',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02ebe77ec25e43c99d2003fff1',
        orderNr: 13,
        shortCode: 'bg',
    },
    SRB: {
        name: 'Serbia',
        artist: 'TEYA DORA',
        song: 'RAMONDA',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e024f09a7ae22e0b3fb5b278266',
        orderNr: 16,
        shortCode: 'rs',
    },
    EST: {
        name: 'Estland',
        artist: '5MIINUST x Puuluup',
        song: '(nendest) narkootikumidest ei tea me (küll) midagi',
        artistImgURL: 'https://i.scdn.co/image/ab67616d00001e02ddef53d78dd42510cdcb6f42',
        orderNr: 9,
        shortCode: 'ee',
    },
};

export const getName = (code) => {
    const country = allCountries[code];
    return !!country ? country.name : undefined;
};

export const getOrder = (code) => {
    const country = allCountries[code];
    return !!country ? country.orderNr : undefined;
};

export const getArtist = (code) => {
    const country = allCountries[code];
    return !!country ? country.artist : undefined;
};

export const getSong = (code) => {
    const country = allCountries[code];
    return !!country ? country.song : undefined;
};

export const getArtistImage = (code) => {
    const country = allCountries[code];
    return !!country ? country.artistImgURL : undefined;
};

export const getImage = (code) => {
    const country = allCountries[code];
    return !!country ? `https://flagcdn.com/256x192/${country.shortCode}.png` : undefined;
};

export const CurrentScoresCollection = 'scores24';
export default allCountries;
