import React from 'react';
import { ThemeProvider } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom/dist';
import { muiTheme } from '../../theme';
import classNames from 'classnames';
import { useWindowSize } from '../../hooks';
const routePaths = ['/stage', '/stats'];
const CustomContainer = ({ children }) => {
    const { pathname } = useLocation();
    const { width } = useWindowSize();
    const isMobile = width < 900;
    const match = routePaths.find((p) => matchPath(p, pathname));
    return match ? (
        <ThemeProvider theme={muiTheme}>
            <div className={classNames('stage-container', isMobile && 'container')}>{children}</div>
        </ThemeProvider>
    ) : (
        <div className="d-flex align-items-center justify-content-center">
            <div className="container">{children}</div>
        </div>
    );
};
export default CustomContainer;
