import { Box, Button, Grid, Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useStageContext } from '../../contexts/StageContext';
import { db, firestoreTime } from '../../firebase/config';
import { getName } from '../../static/countries';
import { CurrentScoresCollection } from '../../static/countries';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#0D0C1D',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const VotePanel = () => {
    const { currentUser } = useAuth();
    const { currentRound, openModal, setOpenModal, loading, setLoading } = useStageContext();
    const [selectedScore, setSelectedScore] = useState();
    const [selectedCountry, setSelectedCountry] = useState();

    const handleScoreChange = (e) => {
        const val = e.currentTarget.value;
        setSelectedScore(val);
    };

    useEffect(() => {
        if (!selectedCountry) {
            // Hvis ingen land er valgt, set valg land når runden endres
            setSelectedCountry(currentRound);
        }
        if (!openModal) {
            // Ikke endre valgt land mens modal er åpen
            setSelectedCountry(currentRound.country);
        }
    }, [currentRound, openModal, selectedCountry]);

    const handleScoreSubmit = (e) => {
        e.preventDefault();
        if (!selectedScore || loading) {
            return;
        }
        const newScore = {
            active: true,
            countryCode: selectedCountry,
            score: selectedScore,
            createdTime: firestoreTime.now(),
            user: { userId: currentUser.uid, email: currentUser.email, displayName: currentUser.displayName },
        };
        setLoading(true);
        deactivateScoresForCountryAndUser(selectedCountry, currentUser.uid).then(() => {
            db.collection(CurrentScoresCollection)
                .add(newScore)
                .then(() => {
                    console.log('New score registered!');
                    setOpenModal(false);
                    setSelectedScore(null);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error registering score: ', error);
                    setOpenModal(false);
                    setLoading(false);
                });
        });
    };
    const deactivateScoresForCountryAndUser = (countryCode, userId) => {
        return db
            .collection(CurrentScoresCollection)
            .where('user.userId', '==', userId)
            .where('countryCode', '==', countryCode)
            .where('active', '==', true)
            .get()
            .then((query) => {
                query.docs.forEach((q) => {
                    q.ref.update({ active: false });
                });
            })
            .catch((error) => {
                console.log('Could not delete old scores', error);
            });
    };

    const isChosen = (value) => {
        return !!selectedScore && Number(selectedScore) === Number(value);
    };

    const handleClose = () => {
        setSelectedScore(null);
        setOpenModal(false);
    };
    return (
        <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4>Hvor mange poeng vil du gi {getName(selectedCountry)}?</h4>
                    </Grid>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 10, 12].map((point) => (
                        <Grid item xs={4} key={point}>
                            <Button variant="contained" value={point} onClick={handleScoreChange} color={isChosen(point) ? 'success' : 'primary'}>
                                {point}
                            </Button>
                        </Grid>
                    ))}
                    {selectedScore && (
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleScoreSubmit} color="secondary">
                                Stem
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Modal>
    );
};
export default VotePanel;
