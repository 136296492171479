import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CurrentScoresCollection, getName } from '../../static/countries';
import { db } from '../../firebase/config';

const stylingSelected = { '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'rgba(105,105,105, 0.3)' };
const stylingNotSelected = { '&:last-child td, &:last-child th': { border: 0 } };

export const Scoretable = ({ country, currentUserId }) => {
    const [allScores, setAllScores] = useState();
    useEffect(() => {
        db.collection(CurrentScoresCollection)
            .where('active', '==', true)
            .where('countryCode', '==', country)
            .onSnapshot((querySnapshot) => {
                var scores = [];
                querySnapshot.forEach((doc) => {
                    scores.push(doc.data());
                });
                scores.sort((x, y) => x.createdTime - y.createdTime);
                scores.reverse();
                setAllScores(scores);
            });
    }, [country]);

    const lagKlokkeslettFraTimestamp = (ts) => {
        return dayjs.unix(ts.seconds).format('LTS');
    };

    if (!allScores || allScores.length < 1) {
        return <p>Ingen har stemt på {getName(country)} enda :(</p>;
    }
    return (
        <TableContainer component={Paper} key={country}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Bruker</TableCell>
                        <TableCell align="right">Poeng</TableCell>
                        <TableCell align="right">Tid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allScores.map((score) => (
                        <TableRow key={score.user.userId} sx={score.user.userId === currentUserId ? stylingSelected : stylingNotSelected}>
                            <TableCell scope="row">{score.user.displayName}</TableCell>
                            <TableCell align="right">{score.score}</TableCell>
                            <TableCell align="right">{lagKlokkeslettFraTimestamp(score.createdTime)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Scoretable;
