import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { CurrentScoresCollection, getName } from '../../static/countries';
import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer, Box } from '@mui/material';
import { Typography, Paper } from '@mui/material';

import { get12Count, getOneCount, getScoresGroupedByCountry, getScoresGroupedByUsers, getUserAvg, getUserTotal } from './statspanelHelpers';
import { useWindowSize } from '../../hooks';
import { useSettings } from '../../contexts/SettingsContext';

const StatsPanel = () => {
    const [groupedByCountry, setGroupedByCountry] = useState();
    const [groupedByUser, setGroupedByUser] = useState();
    const [countryStatSlice, setCountryStatSlice] = useState(10);
    /* eslint-disable no-unused-vars */
    const [timer, setTimer] = useState(5);
    /* eslint-enable no-unused-vars */
    const { settings } = useSettings();
    const autoSwitch = settings?.autoSwitch || false;
    const autoSwitchDuration = settings?.autoSwitchDuration || 5;
    const showStats = settings?.showStats || false;
    const { width } = useWindowSize();
    const isMobile = width < 900;
    useEffect(() => {
        db.collection(CurrentScoresCollection)
            .where('active', '==', true)
            .onSnapshot((querySnapshot) => {
                var scores = [];
                querySnapshot.forEach((doc) => {
                    scores.push(doc.data());
                });

                setGroupedByCountry(getScoresGroupedByCountry(scores));
                setGroupedByUser(getScoresGroupedByUsers(scores));
            });
    }, []);
    useEffect(() => {
        let timerInterval;
        if (autoSwitch) {
            timerInterval = setInterval(() => {
                setTimer((oldProgress) => {
                    if (oldProgress === 0) {
                        const slice =
                            countryStatSlice === groupedByCountry.length
                                ? 10
                                : countryStatSlice + 10 > groupedByCountry.length
                                ? groupedByCountry.length
                                : countryStatSlice + 10;
                        setCountryStatSlice(slice);
                        return autoSwitchDuration;
                    }

                    return Math.min(oldProgress - 1, autoSwitchDuration);
                });
            }, 1000);
        } else {
            clearInterval(timerInterval);
            // setCountryStatSlice(10);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [groupedByCountry, countryStatSlice, autoSwitch, autoSwitchDuration]);

    if (!showStats || !groupedByCountry || !groupedByUser) return <div></div>;
    return (
        <Box className="stats-panel" sx={isMobile ? { display: 'flex', flexDirection: 'column' } : { display: 'flex' }}>
            {groupedByUser?.length > 0 && (
                <Box className="users" sx={!isMobile && { marginRight: '7rem' }}>
                    <Typography variant="h3">Total poeng gitt</Typography>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" sx={{ overflowX: 'auto' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Navn</TableCell>
                                    <TableCell align="right">Poeng</TableCell>
                                    <TableCell align="right">Gj. sn</TableCell>
                                    <TableCell align="right">Ant. 12</TableCell>
                                    <TableCell align="right">Ant. 1</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupedByUser
                                    .sort((a, b) => getUserAvg(b.allscores) - getUserAvg(a.allscores))
                                    .map((row) => (
                                        <TableRow key={row.user.displayName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell scope="row" sx={{ textTransform: 'none' }}>
                                                {row.user.displayName}
                                            </TableCell>
                                            <TableCell align="right">{getUserTotal(row.allscores)}</TableCell>
                                            <TableCell align="right">{getUserAvg(row.allscores).toFixed(2)}</TableCell>
                                            <TableCell align="right">{get12Count(row.allscores)}</TableCell>
                                            <TableCell align="right">{getOneCount(row.allscores)}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}

            {groupedByCountry?.length > 0 && (
                <div className="countries">
                    <Typography variant="h3">Land</Typography>
                    <Box sx={{ minHeight: 600 }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Land</TableCell>
                                        <TableCell align="right">Poeng</TableCell>
                                        <TableCell align="right">Gj. sn</TableCell>
                                        <TableCell align="right">Ant. 12</TableCell>
                                        <TableCell align="right">Ant. 1</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groupedByCountry
                                        .sort((a, b) => Number(b.score) - Number(a.score))
                                        // .slice(0, 10)
                                        .slice(
                                            countryStatSlice - 10 < 0
                                                ? 0
                                                : countryStatSlice === groupedByCountry.length
                                                ? countryStatSlice - (groupedByCountry.length % 10)
                                                : countryStatSlice - 10,
                                            countryStatSlice
                                        )
                                        .map((row) => (
                                            <TableRow key={row.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell scope="row" sx={{ textTransform: 'none' }}>
                                                    {getName(row.code)}
                                                </TableCell>
                                                <TableCell align="right">{row.score}</TableCell>
                                                <TableCell align="right">{getUserAvg(row.allscores).toFixed(2)}</TableCell>
                                                <TableCell align="right">{get12Count(row.allscores)}</TableCell>
                                                <TableCell align="right">{getOneCount(row.allscores)}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            )}
        </Box>
    );
};

export default StatsPanel;
