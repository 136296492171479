import React from 'react';
import ChatIcon from '@mui/icons-material/Chat';

import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';
import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import Chat from './Chat';
import { useAuth } from '../../contexts/AuthContext';
import { isStage } from '../Stage/Admins';
const ChatModal = () => {
    const { currentUser } = useAuth();
    const [messages, setMessages] = useState([]);
    const [messagesCount, setMessagesCount] = useState(0);
    const [readCount, setReadCount] = useState(0);
    const [chatOpen, setChatOpen] = useState(false);
    useEffect(() => {
        const localRead = localStorage.getItem('chat_read_count');
        if (localRead) {
            const readNumber = Number(localRead);
            if (!isNaN(readNumber)) setReadCount(readNumber);
        }
        db.collection('messages').onSnapshot((querySnapshot) => {
            setMessages(querySnapshot.docs);
        });
    }, []);
    useEffect(() => {
        setMessagesCount(messages.length);
        if (chatOpen) {
            setReadCount(messages.length);
            localStorage.setItem('chat_read_count', messages.length.toString());
        }
    }, [messages, chatOpen]);

    const onFabClick = () => {
        setChatOpen(true);
        setReadCount(messagesCount);
        localStorage.setItem('chat_read_count', messagesCount.toString());
    };
    const onClose = () => setChatOpen(false);
    if (!currentUser || isStage(currentUser?.uid)) return <></>;
    const unread = messagesCount - readCount;
    return (
        <div>
            <Fab
                color="secondary"
                sx={{
                    position: 'fixed',
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                }}
                onClick={onFabClick}
            >
                <Badge badgeContent={unread < 0 ? 0 : unread} color="primary">
                    <ChatIcon />
                </Badge>
            </Fab>
            <Dialog open={chatOpen} onClose={onClose}>
                <Chat />
            </Dialog>
        </div>
    );
};
export default ChatModal;
