import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useStageContext } from '../../contexts/StageContext';
import { db } from '../../firebase/config';
import { CurrentScoresCollection, getName } from '../../static/countries';
import { isStage } from './Admins';
import VotePanel from './VotePanel';

const CurrentCountryStats = ({ country }) => {
    const { currentUser } = useAuth();
    const { currentRound, setOpenModal } = useStageContext();
    const [allScores, setAllScores] = useState();
    const isStageAccount = isStage(currentUser.uid);

    useEffect(() => {
        db.collection(CurrentScoresCollection)
            .where('active', '==', true)
            .onSnapshot((querySnapshot) => {
                var scores = [];
                querySnapshot.forEach((doc) => {
                    scores.push(doc.data());
                });
                setAllScores(scores);
            });
    }, [country]);
    const getCurrentCountryPlacement = () => {
        const grouped = allScores.reduce((acc, cur) => {
            if (!acc[cur.countryCode]) {
                acc[cur.countryCode] = Number(cur.score);
            } else {
                const currentScore = acc[cur.countryCode];
                acc[cur.countryCode] = currentScore + Number(cur.score);
            }
            return acc;
        }, {});
        const keys = Object.keys(grouped);
        keys.sort((a, b) => grouped[b] - grouped[a]);
        return [keys.indexOf(country) + 1, grouped[country]];
    };

    const handleOpen = () => setOpenModal(true);

    if (!allScores) return <div></div>;
    const [currentPlacement, currentScore] = getCurrentCountryPlacement();
    return (
        <>
            {!!allScores && (
                <Box className="country-stats">
                    {!!currentPlacement && <Typography variant="h6">Plassering: {currentPlacement}</Typography>}
                    {!!currentScore && <Typography variant="h6">Poeng: {currentScore}</Typography>}
                    {!isStageAccount && (
                        <Grid item xs={12}>
                            <Button sx={{ mt: 1 }} color="info" variant="contained" onClick={handleOpen}>
                                Stem på {getName(currentRound.country)}
                            </Button>
                            <VotePanel />
                        </Grid>
                    )}
                </Box>
            )}
        </>
    );
};
export default CurrentCountryStats;
