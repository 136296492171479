import React, { useRef } from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as PieChartIcon } from '../../resources/pie-chart.svg';
import { ReactComponent as StageIcon } from '../../resources/stage.svg';
import { ReactComponent as TimeTableIcon } from '../../resources/timetable.svg';
import { ReactComponent as ProfileIcon } from '../../resources/profile.svg';
import { ReactComponent as StatsIcon } from '../../resources/stats.svg';
import { StyledMenu } from './MenuItems.styled';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from 'react-bootstrap';
import { useOnClickOutside } from '../../hooks';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import isAdmin from '../Stage/Admins';
import { useSettings } from '../../contexts/SettingsContext';

const PieChart = styled(PieChartIcon)`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;

const Stage = styled(StageIcon)`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;

const TimeTable = styled(TimeTableIcon)`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;
const Profile = styled(ProfileIcon)`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;
const Stats = styled(StatsIcon)`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;
const MenuItems = ({ open, setOpen }) => {
    const menuRef = useRef();
    const theme = useTheme();
    const { settings } = useSettings();
    const { logout, currentUser } = useAuth();
    const onMenuClose = () => setOpen(false);
    const onMenuOpen = () => setOpen(true);
    useOnClickOutside(menuRef, () => setOpen(false));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerWidth = matches ? '100%' : 'auto';
    const showStats = settings?.showStats || false;
    return (
        <SwipeableDrawer
            open={open}
            variant="temporary"
            anchor="left"
            onClose={onMenuClose}
            onOpen={onMenuOpen}
            sx={{
                [`& .MuiDrawer-paper`]: { width: drawerWidth },
            }}
        >
            <StyledMenu>
                {isAdmin(currentUser.uid) && (
                    <Link to="/settings" onClick={onMenuClose}>
                        Innstillinger
                    </Link>
                )}
                <Link to="/" onClick={onMenuClose}>
                    <Profile />
                    Profil
                </Link>
                <Link to="scoreboard" onClick={onMenuClose}>
                    <PieChart />
                    Poengtavle
                </Link>
                <Link to="/timetable" onClick={onMenuClose}>
                    <TimeTable />
                    Tidsskjema
                </Link>
                <Link to="/stage" onClick={onMenuClose}>
                    <Stage />
                    Scenen
                </Link>
                {!!showStats && (
                    <Link to="/stats" onClick={() => setOpen(!open)}>
                        <Stats />
                        Statistikk
                    </Link>
                )}
                <Button onClick={logout}>Logg ut</Button>
            </StyledMenu>
        </SwipeableDrawer>
    );
};
MenuItems.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};
export default MenuItems;
