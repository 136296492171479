import React, { useRef, useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import getErrorMsg from './ErrorMessages';
export const formGroupStyle = { marginBottom: '1rem' };
const Signup = () => {
    const emailRef = useRef();
    const nameRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    async function handleSubmit(e) {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setError('Passord matcher ikke!');
        }
        try {
            setLoading(true);
            setError('');
            const res = await signup(emailRef.current.value, passwordRef.current.value);
            if (res.user) {
                await res.user.updateProfile({ displayName: nameRef.current.value });
                history.push('/');
            }
        } catch (e) {
            const translatedMessage = getErrorMsg(e.code);
            const errorMessage = translatedMessage ? translatedMessage : 'Klarte ikke opprette brukeren. Feilmelding fra systemet: ' + e.message;
            setError(errorMessage);
            setLoading(false);
        }
    }
    return (
        <div>
            <Card>
                <Card.Body className="darkCard">
                    <h2 className="text-center mb-4">Opprett bruker</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="name" style={formGroupStyle}>
                            <Form.Label>Navn</Form.Label>
                            <Form.Control type="text" ref={nameRef} required />
                        </Form.Group>
                        <Form.Group id="email" style={formGroupStyle}>
                            <Form.Label>Epost</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password" style={formGroupStyle}>
                            <Form.Label>Passord</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm" style={formGroupStyle}>
                            <Form.Label>Bekreft passord</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmationRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">
                            Opprett
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Har du allerede en bruker? <Link to="/login">Logg inn</Link>
            </div>
        </div>
    );
};

export default Signup;
