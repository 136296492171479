import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { AuthProvider } from './contexts/AuthContext';
import Frontpage from './Frontpage/Frontpage';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router>
                <AuthProvider>
                    <Frontpage />
                </AuthProvider>
            </Router>
            {/* <Footer /> */}
        </ThemeProvider>
    );
}
export default App;
