import React, { useEffect, useState } from 'react';
import { Paper, Table, TableRow, TableContainer, TableHead, TableCell, TableBody } from '@mui/material';
import { db } from '../../firebase/config';
import { CurrentScoresCollection, getName } from '../../static/countries';
import { useSettings } from '../../contexts/SettingsContext';
import { useWindowSize } from '../../hooks';

const TopScoreTable = () => {
    const [allScores, setAllScores] = useState();
    const [baseScores, setBaseScores] = useState([]);
    const [groupedScores, setGroupedScores] = useState([]);
    const { settings } = useSettings();
    const topScoreSlice = settings?.topScoreSlice || 15;
    const { width } = useWindowSize();
    const isMobile = width < 900;

    useEffect(() => {
        db.collection(CurrentScoresCollection)
            .where('active', '==', true)
            .onSnapshot((querySnapshot) => {
                var scores = [];
                querySnapshot.forEach((doc) => {
                    scores.push(doc.data());
                });
                setAllScores(scores);
            });
    }, []);
    useEffect(() => {
        if (allScores?.length > 0) {
            const grouped = allScores.reduce((acc, cur) => {
                if (!acc[cur.countryCode]) {
                    acc[cur.countryCode] = Number(cur.score);
                } else {
                    const currentScore = acc[cur.countryCode];
                    acc[cur.countryCode] = currentScore + Number(cur.score);
                }
                return acc;
            }, {});
            Object.keys(grouped).sort((a, b) => grouped[a] - grouped[b]);
            const countryList = Object.keys(grouped).map((country) => ({ country: country, score: grouped[country] }));
            countryList.sort((a, b) => Number(b.score) - Number(a.score));
            setBaseScores(countryList);
            setGroupedScores(countryList.slice(topScoreSlice[0] - 1, topScoreSlice[1]));
        }
    }, [allScores, topScoreSlice, isMobile]);
    return (
        <>
            {groupedScores?.length > 0 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Land</TableCell>
                                <TableCell align="right">Poeng</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupedScores.map((country, index) => (
                                <TableRow key={country.country}>
                                    <TableCell>{baseScores.map((c) => c.country).indexOf(country.country) + 1}</TableCell>
                                    <TableCell scope="row">{getName(country.country)}</TableCell>
                                    <TableCell align="right">{country.score}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
export default TopScoreTable;
