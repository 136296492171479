import { Box, Button, Grid, IconButton, Modal } from '@mui/material';
import React, { useState } from 'react';
import { db, firestoreTime } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import allCountries, { CurrentScoresCollection, getName } from '../../static/countries';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#0D0C1D',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    pt: 0,
};

const ScoreboardRow = ({ row, userId }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const [selectedScore, setSelectedScore] = useState();
    const currentUserRow = row.allscores.find((score) => score.user.userId === userId);
    const findCountryName = (countryCode) => {
        return getName(countryCode);
    };
    const findArtistAndSong = (countryCode) => {
        const country = allCountries[countryCode];
        if (!country) {
            return undefined;
        }
        return country.artist + ' - ' + country.song;
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleScoreChange = (e) => {
        const val = e.currentTarget.value;
        setSelectedScore(val);
    };

    const handleScoreSubmit = (e) => {
        e.preventDefault();
        if (!selectedScore || loading) {
            return;
        }
        const newScore = {
            active: true,
            countryCode: row.code,
            score: selectedScore,
            createdTime: firestoreTime.now(),
            user: { userId: currentUser.uid, email: currentUser.email, displayName: currentUser.displayName },
        };
        setLoading(true);
        deactivateScoresForCountryAndUser(row.code, currentUser.uid).then(() => {
            db.collection(CurrentScoresCollection)
                .add(newScore)
                .then(() => {
                    console.log('New score registered!');
                    setOpenModal(false);
                    setSelectedScore(null);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error registering score: ', error);
                    setOpenModal(false);
                    setLoading(false);
                });
        });
    };
    const deactivateScoresForCountryAndUser = (countryCode, userId) => {
        return db
            .collection(CurrentScoresCollection)
            .where('user.userId', '==', userId)
            .where('countryCode', '==', countryCode)
            .where('active', '==', true)
            .get()
            .then((query) => {
                query.docs.forEach((q) => {
                    q.ref.update({ active: false });
                });
            })
            .catch((error) => {
                console.log('Could not delete old scores', error);
            });
    };

    const getUserName = (score) => {
        if (score.user) {
            return score.user.displayName || score.user.email;
        }
        return score.userId;
    };

    const isChosen = (value) => {
        return !!selectedScore && Number(selectedScore) === Number(value);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setSelectedScore(null);
        setOpenModal(false);
    };
    const getRowKey = (score, index) => (score.user?.userId ? score.user.userId + index : Math.random() + index);
    return (
        <TableRow onClick={handleOpenModal}>
            <TableCell>{findCountryName(row.code)}</TableCell>
            <TableCell>{findArtistAndSong(row.code)}</TableCell>
            <TableCell>{row.score}</TableCell>
            <TableCell>{currentUserRow?.score}</TableCell>
            <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <IconButton sx={{ ml: 'auto', display: 'block' }} color="secondary" onClick={handleClose} aria-label="lukk">
                        <CloseIcon />
                    </IconButton>
                    <h4>Hva andre deltakere har gitt {getName(row.code)}</h4>
                    <ul style={{ paddingLeft: '0', listStyle: 'none' }}>
                        {row.allscores
                            .sort((a, b) => Number(b.score) - Number(a.score))
                            .map((score, index) => (
                                <li
                                    key={getRowKey(score, index)}
                                    style={{ backgroundColor: score.user?.userId === userId ? 'rgba(105,105,105, 0.6)' : 'transparent' }}
                                >
                                    {`${getUserName(score)}: ${score.score}`}
                                </li>
                            ))}
                    </ul>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h4>Hvor mange poeng vil du gi {getName(row.code)}?</h4>
                        </Grid>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 10, 12].map((point) => (
                            <Grid item xs={4} key={point}>
                                <Button variant="contained" value={point} onClick={handleScoreChange} color={isChosen(point) ? 'success' : 'primary'}>
                                    {point}
                                </Button>
                            </Grid>
                        ))}
                        {selectedScore && (
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleScoreSubmit} color="secondary">
                                    Stem
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
        </TableRow>
    );
};

export default ScoreboardRow;
