import React, { useRef, useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase/config';
import { CurrentScoresCollection } from '../../static/countries';
import { formGroupStyle } from '../Signup/Signup';

const UpdateProfile = () => {
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const { updatePassword, currentUser } = useAuth();
    const [error, setError] = useState('');
    const [successMessage, settSuccessMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState(false);
    const history = useNavigate();
    const updateMyScores = (newName) => {
        return db
            .collection(CurrentScoresCollection)
            .where('user.userId', '==', currentUser.uid)
            .where('active', '==', true)
            .get()
            .then((query) => {
                query.docs.forEach((q) => {
                    q.ref.update({ user: { userId: currentUser.uid, displayName: newName, email: currentUser.email } });
                });
            })
            .catch((error) => {
                console.log('Could not update scores', error);
            });
    };
    const updateMyMessages = (newName) => {
        return db
            .collection('messages')
            .where('sentBy.userId', '==', currentUser.uid)
            .get()
            .then((query) => {
                query.docs.forEach((q) => {
                    q.ref.update({ sentBy: { userId: currentUser.uid, name: newName } });
                });
            })
            .catch((error) => {
                console.log('Could not update messages', error);
            });
    };
    const handleNameChange = (e) => {
        setNewDisplayName(e.currentTarget.value);
    };
    function handleSubmit(e) {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setError('Passord matcher ikke!');
        }
        const promises = [];
        setError('');
        setLoading(true);
        const oppdaterteElementer = [];

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
            oppdaterteElementer.push('Passord');
        }
        if (newDisplayName) {
            promises.push(currentUser.updateProfile({ displayName: newDisplayName }));
            oppdaterteElementer.push('Brukernavn');
        }

        Promise.all(promises)
            .then(() => {
                if (newDisplayName) {
                    updateMyScores(newDisplayName).then(() => {
                        updateMyMessages(newDisplayName).then(() => {
                            history.push('/');
                        });
                    });
                }
            })
            .catch(() => {
                setError('Klarte ikke oppdatere passord');
            })
            .finally(() => {
                setLoading(false);
                if (!error) {
                    settSuccessMessage('Suksess! Vi har endret følgende: ' + oppdaterteElementer.join(', '));
                }
            });
    }
    return (
        <div>
            <Card>
                <Card.Body className="darkCard">
                    <h2 className="text-center mb-4">Endre bruker</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="name" style={formGroupStyle}>
                            <Form.Label>Navn</Form.Label>
                            <Form.Control type="text" onChange={handleNameChange} placeholder="" />
                        </Form.Group>
                        <Form.Group id="password" style={formGroupStyle}>
                            <Form.Label>Passord</Form.Label>
                            <Form.Control type="password" ref={passwordRef} placeholder="La være tom hvis du ikke vil bytte passord" />
                        </Form.Group>
                        <Form.Group id="password-confirm" style={formGroupStyle}>
                            <Form.Label>Bekreft passord</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmationRef} />
                        </Form.Group>
                        <div className="verticalSpace" />
                        <Button disabled={loading} className="w-100" type="submit">
                            Oppdater
                        </Button>
                        <div className="verticalSpace" />
                        {successMessage && (
                            <>
                                <Alert severity="success">{successMessage}</Alert>
                            </>
                        )}
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Link to="/">Avbryt</Link>
            </div>
        </div>
    );
};

export default UpdateProfile;
