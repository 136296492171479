const FEILMELDINGER = [
    {
        code: "auth/email-already-in-use",
        messageEng: "The email address is already in use by another account.",
        messageNor: "Denne eposten er allerede i bruk av enn annen bruker",
    },
    {
        code: "auth/weak-password",
        messageEng: "The provided value for the password user property is invalid. It must be a string with at least six characters.",
        messageNor: "Oppgitt passord tilfredstiller ikke kravene. Det må inneholde minst 6 tegn."
    },
    {
        code: "auth/invalid-email",
        messageEng: "The provided value for the email user property is invalid. It must be a string email address.",
        messageNor: "Oppgitt epostadresse er ikke på gyldig format. Den må inneholde @, domenenavn og toppnivådomene."
    }
];

const getErrorMsg = (code) => {
    const match = FEILMELDINGER.find(fm => fm.code === code);
    return match ? match.messageNor : undefined;
}

export default getErrorMsg;