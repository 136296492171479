import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import isAdmin from '../Stage/Admins';
import { useAuth } from '../../contexts/AuthContext';

const Dashboard = () => {
    const [error, setError] = useState('');
    const { currentUser, logout } = useAuth();
    const history = useNavigate();
    async function handleLogout() {
        setError('');
        try {
            await logout();
            history.push('/login');
        } catch {
            setError('Klarte ikke å logge ut');
        }
    }
    return (
        <>
            <Card>
                <Card.Body className="darkCard">
                    <h2 className="text-center mb-4">Hei, {currentUser.displayName}</h2>
                    {isAdmin(currentUser.uid) && (
                        <h6 className="text-center mb-4">Du er administrator for escgjengen</h6>
                    )}
                    {error && <Alert variant="danger">{error}</Alert>}
                    <strong>Epost:</strong>
                    {currentUser.email}

                    <Link to="/update-profile" className="btn btn-primary w-100 -mt3">
                        Oppdater profil{' '}
                    </Link>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>
                    Logg ut
                </Button>
            </div>
        </>
    );
};

export default Dashboard;
