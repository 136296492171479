import styled from 'styled-components';

export const ChatStyled = styled.div`
    .messages-panel {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: auto;
        padding: 0.5rem;
        background-color: #464646;
    }
    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        margin: 10px 0;
        word-break: break-word;
        max-width: 200px;
    }
    .name {
        color: #8cff8c;
        font-size: 0.8rem;
    }
    .date {
        font-size: 14px;
        color: white;
    }
    .text {
        background: lightgrey;
        color: black;
        padding: 5px 10px;
        border-radius: 10px;
    }
    .message.own {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: flex-end;
    }
    .chat-form {
        display: flex;
        align-items: center;
        margin: 5px;
    }
    .chat-input {
        border: 1px solid grey;
        border-radius: 5px;
        padding: 0.5rem 0.75rem;
    }
`;
