import React from 'react';
import { bool, func } from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import MenuItems from '../MenuItems';
import Burger from '../Burger';

const Menu = ({ isOpen, setOpenCallback }) => {
    const { currentUser } = useAuth();
    if (!currentUser) {
        return null;
    }
    return (
        <>
            <Burger open={isOpen} setOpen={setOpenCallback} />
            <MenuItems open={isOpen} setOpen={setOpenCallback} />
        </>
    );
};
Menu.propTypes = {
    setOpenCallback: func.isRequired,
    isOpen: bool.isRequired,
};

export default Menu;
