import { Button, Divider, FormControlLabel, FormGroup, Paper, Slider, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isAdmin from '../components/Stage/Admins';
import { useAuth } from '../contexts/AuthContext';
import { useSettings } from '../contexts/SettingsContext';

const SettingsPanel = () => {
    const { currentUser } = useAuth();
    const { updateSettings, settings } = useSettings();
    const [settingsState, setSettingsState] = useState();

    useEffect(() => {
        setSettingsState(settings);
    }, [settings]);
    if (!isAdmin(currentUser.uid)) return <div></div>;
    const updateLocalSettings = (value) => {
        setSettingsState({ ...settingsState, ...value });
    };
    const updateShowStats = () => updateLocalSettings({ showStats: !settingsState.showStats });
    const updateAutoSwitch = () => updateLocalSettings({ autoSwitch: !settingsState.autoSwitch });
    const updateAutoSwitchDuration = (event) => updateLocalSettings({ autoSwitchDuration: event.target.value });
    const updateTopScoreSlice = (event, value) => updateLocalSettings({ topScoreSlice: value });
    const onUpdateClick = () => {
        updateSettings(settingsState);
    };
    return (
        <Paper sx={{ padding: '1.5rem' }}>
            {!!settingsState && (
                <>
                    <Typography variant="h3">Hei admin</Typography>
                    <FormGroup>
                        <Typography variant="h5">Visning av stats</Typography>
                        <FormControlLabel control={<Switch checked={settingsState.showStats} onChange={updateShowStats} />} label="Vis statistikk" />
                        <FormControlLabel
                            control={<Switch checked={settingsState.autoSwitch} onChange={updateAutoSwitch} />}
                            label="Benytte auto switch på stats"
                        />
                        <FormControlLabel
                            control={
                                <Slider
                                    sx={{ maxWidth: '250px' }}
                                    value={settingsState.autoSwitchDuration || 15}
                                    valueLabelDisplay="on"
                                    step={10}
                                    marks
                                    min={5}
                                    max={120}
                                    onChange={updateAutoSwitchDuration}
                                />
                            }
                            label="Varighet"
                        />
                    </FormGroup>
                    <Divider sx={{ margin: '1rem 0 1rem 0' }} />
                    <FormGroup>
                        <Typography variant="h5">Visning av topscore på scene</Typography>

                        <FormControlLabel
                            control={
                                <Slider
                                    sx={{ maxWidth: '250px' }}
                                    value={settingsState.topScoreSlice || [0, 15]}
                                    valueLabelDisplay="on"
                                    step={1}
                                    marks
                                    min={1}
                                    max={26}
                                    onChange={updateTopScoreSlice}
                                />
                            }
                            label="Slice på topscore scene"
                        />
                        {JSON.stringify(settingsState)}
                        <Button color="primary" variant="contained" onClick={onUpdateClick}>
                            Oppdater
                        </Button>
                    </FormGroup>
                </>
            )}
        </Paper>
    );
};
export default SettingsPanel;
