import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase/config';
import { ChatStyled } from './Chat.styled';
import ChatMessage from './ChatMessage';
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
var relativeTime = require('dayjs/plugin/relativeTime');
var localizedFormat = require('dayjs/plugin/localizedFormat');
require('dayjs/locale/nb');

dayjs.locale('nb');
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const Chat = () => {
    const chatBottomRef = useRef(null);
    const [messages, setMessages] = useState();
    const [inputMessage, setInputMessage] = useState('');
    const { currentUser } = useAuth();
    useEffect(() => {
        db.collection('messages')
            .orderBy('sentAt')
            .onSnapshot((querySnapshot) => {
                var data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                setMessages(data);
            });
    }, []);
    const onInputChange = (e) => {
        setInputMessage(e.currentTarget.value);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (inputMessage === '') return;
        const newMessage = {
            sentBy: { userId: currentUser.uid, name: currentUser.displayName },
            sentAt: new Date(),
            messageText: inputMessage,
        };
        setInputMessage('');
        db.collection('messages').add(newMessage);
    };
    useEffect(() => {
        if (chatBottomRef.current) {
            chatBottomRef.current.scrollIntoView();
        }
    }, [messages]);
    return (
        <ChatStyled>
            <div className="chat">
                <div className="messages-panel">
                    {messages &&
                        messages.length > 0 &&
                        messages.map((message, index) => <ChatMessage key={message + index} message={message} currentUser={currentUser} />)}
                    <div ref={chatBottomRef}></div>
                </div>
                <div className="input-panel">
                    <form onSubmit={onSubmit} className="chat-form">
                        <input className="chat-input" required type="text" value={inputMessage} onChange={onInputChange} />
                        <IconButton type="submit">
                            <SendIcon />
                        </IconButton>
                    </form>
                </div>
            </div>
        </ChatStyled>
    );
};
export default Chat;
