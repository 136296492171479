import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Menu, Chat, Scoreboard, Contestants, Signup, Dashboard, Login, PrivateRoute, ForgotPassword, UpdateProfile, Stage } from '../components';
import CustomContainer from '../components/CustomContainer/CustomContainer';
import ChatModal from '../components/Chat/ChatModal';
import SettingsPanel from '../Settings/SettingsPanel';
import { SettingsProvider } from '../contexts/SettingsContext';
import StatsPanel from '../components/StatsPanel/Statspanel';
import { useAuth } from '../contexts/AuthContext';

const Frontpage = () => {
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    if (!currentUser) {
        return (
            <CustomContainer>
                <Routes>
                    <Route exact path="/" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                </Routes>
            </CustomContainer>
        );
    }
    return (
        <>
            <SettingsProvider>
                <Menu setOpenCallback={setOpen} isOpen={open} />
                <CustomContainer>
                    <ChatModal />
                    <Routes>
                        <Route path="/login" element={currentUser ? <PrivateRoute><Dashboard /></PrivateRoute> : <Login />} />
                        <Route exact path="/" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
                        <Route exact path="/update-profile" element={<PrivateRoute><UpdateProfile /> </PrivateRoute>} />
                        <Route exact path="/scoreboard" element={<PrivateRoute><Scoreboard /> </PrivateRoute>} />
                        <Route exact path="/chat" element={<PrivateRoute><Chat /> </PrivateRoute>} />
                        <Route exact path="/timetable" element={<PrivateRoute><Contestants /> </PrivateRoute>} />
                        <Route exact path="/stage" element={<PrivateRoute><Stage /> </PrivateRoute>} />
                        <Route exact path="/stats" element={<PrivateRoute><StatsPanel /> </PrivateRoute>} />
                        <Route exact path="/settings" element={<PrivateRoute><SettingsPanel /> </PrivateRoute>} />
                    </Routes>
                </CustomContainer>
            </SettingsProvider>
        </>);
};
export default Frontpage;
