export const getScoresGroupedByCountry = (scores) => {
    return scores.reduce((acc, cur) => {
        if (!acc) {
            return [{ code: cur.countryCode, allscores: [cur], score: cur.score }];
        }
        let current = acc.find((a) => a.code === cur.countryCode);
        if (current) {
            current.allscores.push(cur);
            current.score = (Number(current.score) + Number(cur.score)).toString();
        } else {
            acc.push({ code: cur.countryCode, allscores: [cur], score: cur.score });
        }
        return acc;
    }, []);
};
export const getScoresGroupedByUsers = (scores) => {
    return scores.reduce((acc, cur) => {
        if (!acc) {
            return [{ code: cur.countryCode, allscores: [cur], user: cur.user }];
        }
        let current = acc.find((a) => a.user.userId === cur.user.userId);
        if (current) {
            current.allscores.push(cur);
        } else {
            acc.push({ code: cur.countryCode, allscores: [cur], user: cur.user });
        }
        return acc;
    }, []);
};

const get12PointScores = (scoreList) => scoreList.filter((score) => score.score === '12').length;
const getOnePointScores = (scoreList) => scoreList.filter((score) => score.score === '1').length;
const sumScores = (scoreList) => scoreList.reduce((total, score) => total + Number(score.score), 0);

/* User stats */
export const get12Stats = (groupedByUser) => {
    return groupedByUser.reduce((acc, cur) => {
        if (Object.keys(acc).length === 0) {
            return { most: { user: cur.user, count: get12PointScores(cur.allscores) }, least: { user: cur.user, count: get12PointScores(cur.allscores) } };
        }
        const currentCount = get12PointScores(cur.allscores);
        const result = { ...acc };
        if (currentCount > acc.most?.count) {
            result.most = { user: cur.user, count: currentCount };
        }
        if (currentCount < acc.least?.count) {
            result.least = { user: cur.user, count: currentCount };
        }
        return result;
    }, {});
};
export const getOneStats = (groupedByUser) => {
    return groupedByUser.reduce((acc, cur) => {
        if (Object.keys(acc).length === 0) {
            return { most: { user: cur.user, count: getOnePointScores(cur.allscores) }, least: { user: cur.user, count: getOnePointScores(cur.allscores) } };
        }
        const currentCount = getOnePointScores(cur.allscores);
        const result = { ...acc };
        if (currentCount > acc.most?.count) {
            result.most = { user: cur.user, count: currentCount };
        }
        if (currentCount < acc.least?.count) {
            result.least = { user: cur.user, count: currentCount };
        }
        return result;
    }, {});
};

export const getHighestAndLowestTotal = (groupedByUser) => {
    return groupedByUser.reduce((acc, cur) => {
        if (Object.keys(acc).length === 0) {
            const sum = sumScores(cur.allscores);
            return { highest: { user: cur.user, total: sum }, lowest: { user: cur.user, total: sum } };
        }
        const currentSum = sumScores(cur.allscores);
        const result = { ...acc };
        if (currentSum > acc.highest?.total) {
            result.highest = { user: cur.user, total: currentSum };
        }
        if (currentSum < acc.lowest?.total) {
            result.lowest = { user: cur.user, total: currentSum };
        }
        return result;
    }, {});
};

/* Single user stats */
export const getUserTotal = (allscores) => allscores.reduce((a, b) => Number(a) + Number(b.score), 0);
export const getUserAvg = (allscores) => getUserTotal(allscores) / allscores.length;
export const get12Count = (allscores) => allscores.filter((score) => Number(score.score) === 12).length;
export const getOneCount = (allscores) => allscores.filter((score) => Number(score.score) === 1).length;

/* Country stats */
export const getCountryOneStats = (groupedByCountry) => {
    return groupedByCountry.reduce((acc, cur) => {
        if (Object.keys(acc).length === 0) {
            return { most: { country: cur, count: getOnePointScores(cur.allscores) }, least: { country: cur, count: getOnePointScores(cur.allscores) } };
        }
        const currentCount = getOnePointScores(cur.allscores);
        const result = { ...acc };
        if (currentCount > acc.most?.count) {
            result.most = { country: cur, count: currentCount };
        }
        if (currentCount < acc.least?.count) {
            result.least = { country: cur, count: currentCount };
        }
        return result;
    }, {});
};
export const getCountry12Stats = (groupedByCountry) => {
    return groupedByCountry.reduce((acc, cur) => {
        if (Object.keys(acc).length === 0) {
            return { most: { country: cur, count: get12PointScores(cur.allscores) }, least: { country: cur, count: get12PointScores(cur.allscores) } };
        }
        const currentCount = get12PointScores(cur.allscores);
        const result = { ...acc };
        if (currentCount > acc.most?.count) {
            result.most = { country: cur, count: currentCount };
        }
        if (currentCount < acc.least?.count) {
            result.least = { country: cur, count: currentCount };
        }
        return result;
    }, {});
};
