import React, { useContext, useEffect, useState } from 'react';
import { db } from '../firebase/config';

const SettingsContext = React.createContext();

export const useSettings = () => {
    return useContext(SettingsContext);
};

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState();

    const updateSettings = (value) => {
        const newSettings = { ...settings };
        db.collection('settings')
            .doc('settingsdoc')
            .update({ ...newSettings, ...value });
    };

    const toggleQR = () => {
        const newSettings = { ...settings };
        newSettings.visQrVedScene = !settings.visQrVedScene
        db.collection('settings')
            .doc('settingsdoc')
            .update({ ...newSettings });
    };

    useEffect(() => {
        db.collection('settings').onSnapshot((querySnapshot) => {
            var dbSettings = [];
            querySnapshot.forEach((doc) => {
                dbSettings.push(doc.data());
            });
            setSettings(dbSettings[0]);
        });
    }, []);

    const ctx = {
        settings,
        updateSettings,
        toggleQR,
    };

    return <SettingsContext.Provider value={ctx}>{children}</SettingsContext.Provider>;
};
