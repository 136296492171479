import React, { useRef, useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const ForgotPassword = () => {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setLoading(true);
            setError('');
            setMessage('');
            await resetPassword(emailRef.current.value);
            setMessage('Sjekk innboksen din for videre instruks!');
        } catch {
            setError('Noe gikk galt under tilbakestilling av passord');
        }
    }
    return (
        <div>
            <Card>
                <Card.Body className="darkCard">
                    <h2 className="text-center mb-4">Tilbakestill passord</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>
                                <p>Epost</p>
                            </Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">
                            Tilbakestill passord
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/login">Logg inn</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Har du allerede en bruker? <Link to="/login">Logg inn</Link>
            </div>
        </div>
    );
};

export default ForgotPassword;
