import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { ReactComponent as QRCodeImg } from '../../resources/qrcode.svg';
import { db, firestoreTime } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import isAdmin from './Admins';
import allCountries, { getName, getArtistImage, getArtist, getSong, getImage } from '../../static/countries';
import Scoretable from './Scoretable';

import './stage.scss';
import { Grid, Paper, Typography } from '@mui/material';
import CurrentCountryStats from './CurrentCountryStats';
import TopScoreTable from './TopScoreTable';
import { StageProvider } from '../../contexts/StageContext';
import { useWindowSize } from '../../hooks';
import { useSettings } from '../../contexts/SettingsContext';

const QRCode = styled(QRCodeImg)`
    width: 300px;
    height: 300px;
`;

const StageIndex = () => {
    /* eslint-disable no-unused-vars */
    const [allRounds, setAllRounds] = useState([]);
    /* eslint-enable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [loadingStage, setLoadingStage] = useState(true);
    const [currentRound, setCurrentRound] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('NOR');
    const [error, setError] = useState(false);
    const { currentUser } = useAuth();
    const { width } = useWindowSize();
    const { settings, toggleQR } = useSettings();
    const visQRKode = settings?.visQrVedScene || false;
    const isMobile = width < 900;

    const countryCodes = Object.keys(allCountries).sort((a, b) => getName(a).localeCompare(getName(b)));

    useEffect(() => {
        setLoadingStage(true);
        db.collection('rounds').onSnapshot((querySnapshot) => {
            var rounds = [];
            querySnapshot.forEach((doc) => {
                rounds.push(doc.data());
            });
            rounds.sort((x, y) => x.startTime - y.startTime);
            setAllRounds(rounds);
            if (rounds.length > 0) {
                setCurrentRound(rounds[rounds.length - 1]);
            }
        });
    }, []);

    useEffect(() => {
        if (!!currentRound?.country) {
            setLoadingStage(false);
        }
    }, [allRounds, currentRound]);

    const erAdmin = isAdmin(currentUser.uid);

    function handleSubmit(e) {
        if (loading) return;
        setLoading(true);
        e.preventDefault();
        const newRound = {
            country: selectedCountry,
            startTime: firestoreTime.now(),
        };
        if (!newRound.country) {
            setLoading(false);
            setError(true);
            return;
        }
        db.collection('rounds')
            .add(newRound)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    function handleChange(e) {
        const value = e.target.value;
        if (value) {
            setError(false);
            setSelectedCountry(e.target.value);
        }
    }

    const context = {
        currentRound,
        openModal,
        setOpenModal,
        loading,
        setLoading,
    };
    const adminPanel = (
        <>
            <Grid container>
                {!visQRKode && (
                    <Grid item xs={12} md={6}>
                        <FormControl margin="normal" variant="filled" style={{ backgroundColor: 'white' }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Land</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                label="Age"
                                required
                                defaultValue="NOR"
                                onChange={handleChange}
                            >
                                {countryCodes.map((code) => (
                                    <MenuItem key={code} value={code}>
                                        {getName(code)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Grid item xs={12} md={6}>
                                {error && <Alert severity="error">Du må velge et land for å starte en ny runde!</Alert>}
                            </Grid>
                            <Button margin="normal" variant="contained" onClick={handleSubmit}>
                                Start ny runde
                            </Button>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} md={12}>
                    <Button margin="normal" variant="contained" onClick={toggleQR}>
                        Veksle QR-kode
                    </Button>
                </Grid>
            </Grid>
        </>
    );
    if (loadingStage) return <></>;
    if (visQRKode) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <h1>
                        Scan koden eller gå til <br /> <span style={{ textDecoration: 'underline' }}>https://ESCgjengen.site</span> for å komme til innlogging
                    </h1>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <QRCode />
                </Grid>
                <Grid item xs={4}></Grid>
                {erAdmin && <>{adminPanel}</>}
            </Grid>
        );
    }
    return (
        <div className="stage">
            <StageProvider ctx={context}>
                {!!currentRound?.country && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12} lg={6} xl={4} sx={!isMobile && { marginTop: '3.75rem' }}>
                            <Paper className="title-zone">
                                <Typography variant="h1" sx={{ fontSize: '2rem' }}>
                                    <img className="country-flag" src={getImage(currentRound.country)} width="256" height="192" alt="flag" />
                                    {getName(currentRound.country)}
                                </Typography>
                                <Typography variant="h2" sx={{ fontSize: '2rem' }}>
                                    {getArtist(currentRound.country)}
                                </Typography>
                                <Typography variant="h3" sx={{ fontSize: '1.5rem', mb: 2 }}>
                                    {getSong(currentRound.country)}
                                </Typography>
                                <Box className="stat-box">
                                    <Box component="img" alt={getName(currentRound.country)} src={getArtistImage(currentRound.country)} />
                                    <CurrentCountryStats country={currentRound.country} />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={4}>
                            <Typography variant="h4">Stemmer</Typography>
                            <Scoretable country={currentRound.country} currentUserId={currentUser.uid} />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={4}>
                            <Typography variant="h4">Poeng totalt</Typography>
                            <TopScoreTable />
                        </Grid>
                    </Grid>
                )}
                {erAdmin && <>{adminPanel}</>}
            </StageProvider>
        </div>
    );
};

export default StageIndex;
