import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const app = firebase.initializeApp({
    apiKey: 'AIzaSyA9UiDL24PcHSPz7gSASlPOm3-vlbcovcY',
    authDomain: 'esc21-c4097.firebaseapp.com',
    projectId: 'esc21-c4097',
    storageBucket: 'esc21-c4097.appspot.com',
    messagingSenderId: '165935997989',
    appId: '1:165935997989:web:7d6a4df2f296deefc80bcc',
    measurementId: 'G-VJQLRW78P1',
});

export const db = firebase.firestore();
export const auth = app.auth();
export const firestoreTime = firebase.firestore.Timestamp;
export default app;
