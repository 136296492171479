import React from 'react';
import allCountries from '../../static/countries';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useWindowSize } from '../../hooks';

const Contestants = () => {
    const countryCodes = Object.keys(allCountries);
    const listOfCountries = countryCodes.map((code) => allCountries[code]);
    const orderedCountries = listOfCountries.sort((countryA, countryB) => countryA.orderNr - countryB.orderNr);
    const { width } = useWindowSize();
    const isMobile = width < 500;
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ color: 'white' }}>Deltakerliste</h1>
            <TableContainer component={Paper} sx={!isMobile && { width: 'fit-content', minWidth: '500px' }}>
                <Table sx={!isMobile && { width: 'auto', maxWidth: '100%', minWidth: '500px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Land</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderedCountries.map((country) => (
                            <TableRow>
                                <TableCell>{country.orderNr}</TableCell>
                                <TableCell>{country.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
export default Contestants;
