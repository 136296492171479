import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  h1 {
    color: ${({ theme }) => theme.primaryLight};
    padding-bottom: 16px;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
  }
  h2 {
    color: ${({ theme }) => theme.primaryLight};
  }
  p {
    color: ${({ theme }) => theme.primaryLight};
  }

  img {
    border-radius: 5px;
    height: auto;
    width: 10rem;
  }
  div {
    text-align: center;
  }
  .score-row.voted p {
    color: #b2ffb2;
  }
  table {
    overflow-y: auto;
    max-height: 500px;
    display: block;
  }
  .row-modal {
    background: darkgrey;
    display: flex;
    align-items: center;
  }
  table th {
    text-transform: uppercase;
  }
  small {
    display: block;
  }
  .footer {
    border-top: 1px solid;
    margin-top: 1rem;
    color: ${({ theme }) => theme.primaryGray};
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;  
  }
  .darkCard {
    background: ${({ theme }) => theme.primaryDark};
  }
  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
  }
  .verticalSpace {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;
