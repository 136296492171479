import { createTheme } from '@mui/material/styles';

export const theme = {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryGray: '#3A3B3C',
    primaryHover: '#343078',
    mobile: '576px',
};
export const muiTheme = createTheme({
    typography: {
        fontSize: 20,
    },
});
